import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = '#D6D8DF', height = '30px', width = '30px' }) => (
  <SvgIcon
    viewBox='0 0 512 512'
    style={{
      height,
      width,
    }}
  >
    <g>
      <g>
        <g>
          <path
            fill={color}
            d='M469.333,106.667H362.667V85.333c0-23.531-19.135-42.667-42.667-42.667H192c-23.531,0-42.667,19.135-42.667,42.667
				v21.333H42.667C19.135,106.667,0,125.802,0,149.333v64C0,236.865,19.135,256,42.667,256h170.667v-10.667
				c0-5.896,4.771-10.667,10.667-10.667h64c5.896,0,10.667,4.771,10.667,10.667V256h170.667C492.865,256,512,236.865,512,213.333
				v-64C512,125.802,492.865,106.667,469.333,106.667z M320,106.667H192V85.333h128V106.667z'
          />
          <path
            fill={color}
            d='M506.083,267.51c-3.635-1.802-7.979-1.385-11.188,1.052c-7.583,5.74-16.417,8.771-25.563,8.771H298.667v32
				c0,5.896-4.771,10.667-10.667,10.667h-64c-5.896,0-10.667-4.771-10.667-10.667v-32H42.667c-9.146,0-17.979-3.031-25.563-8.771
				c-3.219-2.458-7.552-2.875-11.188-1.052C2.292,269.313,0,273.01,0,277.063v149.604c0,23.531,19.135,42.667,42.667,42.667h426.667
				c23.531,0,42.667-19.135,42.667-42.667V277.063C512,273.01,509.708,269.313,506.083,267.51z'
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);
